<template>
<div>
  <Row :gutter="16">
    <Col span="6">
      <Card :bordered="false" dis-hover>
        <p slot="title">
          <i class="iconfont icon-gongdan"  style="color: #17233d"></i>
          所有工单
        </p>
        <p>
          <a style="color: #17233d;font-weight: bold; font-size: 24px">{{ tickets.all.length }} </a>单
        </p>
      </Card>
    </Col>
    <Col span="6">
      <Card :bordered="false" dis-hover>
        <p slot="title">
          <i class="iconfont icon-daiban" style="color: #ff9900;"></i>
          将超时工单
        </p>
        <p>
          <a style="color: #ff9900;font-weight: bold; font-size: 24px">{{ tickets.all.length }} </a>单
        </p>
      </Card>
    </Col>
    <Col span="6">
      <Card :bordered="false" dis-hover>
        <p slot="title">
          <i class="iconfont icon-chaoshigongdan" style="color: #ed4014"></i>
          已超时工单
        </p>
        <p>
          <a  style="color: #ed4014;font-weight: bold; font-size: 24px">{{ tickets.all.length }} </a>单
        </p>
      </Card>
    </Col>
    <Col span="6">
      <Card :bordered="false" dis-hover>
        <p slot="title">
          <i class="iconfont icon-wanchenggongdan" style="color: #19be6b;"></i>
          已完成工单
        </p>
        <p>
          <a  style="color: #19be6b;font-weight: bold; font-size: 24px">{{ tickets.all.length }} </a>单
        </p>
      </Card>
    </Col>
  </Row>
  <Card :bordered="false" dis-hover style="margin-top: 12px">
    <list-tab :tickets="tickets" @getTickets="getTickets"></list-tab>
  </Card>
</div>
</template>

<script>
import {ckquery, get} from "@/api/http"
import moment from "moment";
import ListTab from "./ListTab"

export default {
  name: "Tickets",
  components: {
    ListTab
  },
  data () {
    return {
      tickets: {all :[], owner: [], backlog: [], apply: []},
    }
  },
  methods: {
    dateFormat(date){
      return moment(date).utcOffset(8).format()
    },
    getTickets() {
      get("/api/v1.0/tickets?category=all")
        .then((resp)=>{
          this.tickets.all = resp.data.value;
        })
        .catch((e)=>{
          console.log(e)
        })
    },
  },
  computed: {},
  created() {
    this.getTickets();
  }
}
</script>

<style scoped>
.ql-toolbar.ql-snow{
  text-align: left;
}
.quill-editor{
  height: 390px;
}
.ivu-form-item {
  margin-bottom: 0;
}

/deep/ .ivu-table-expanded-cell {
  padding: 0;
}

/deep/ .ivu-tabs-bar {
  margin-bottom: 0;
}

/deep/ .ivu-table th {
  background-color: #dcdee200;
}
</style>